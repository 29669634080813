import { HawkSearchComponents, PageSizeComponentConfig } from '@configuration';
import { PageSizeComponentModel, PaginationOption } from '@models';
import { searchService } from '@services';
import { BaseComponent } from '../../base.component';
import defaultHtml from './page-size.component.hbs';

/**
 * The Page Size component is used to modify the number of search results displayed on each page.
 *
 * *Note: When the page size changes, the first page will automatically be selected.*
 *
 * ## Tag
 * The tag for this component is `<hawksearch-page-size>`.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-page-size | |
 *
 * When a `select` element with this attribute changes in value, the search will be updated to use that value as the new page size.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./page-size.component.hbs}
 *
 * @category Search
 */
export class PageSizeComponent extends BaseComponent<PageSizeComponentConfig, Array<PaginationOption>, PageSizeComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'page-size';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = true;

    protected override renderContent(): boolean {
        return !!this.data?.length;
    }

    protected override getContentModel(): PageSizeComponentModel {
        return {
            options: this.data!
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-page-size]').forEach((e) => {
            e.addEventListener('change', (event: Event) => {
                event.preventDefault();

                const element = event.currentTarget as HTMLSelectElement;
                const pageSize = parseInt(element.value);

                searchService.setPageSize(pageSize);
            });
        });
    }
}
