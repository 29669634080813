import { FacetsListComponentConfig, HawkSearchComponents } from '@configuration';
import { Facet, FacetState, FacetType, FacetValue, FacetsListComponentModel } from '@models';
import { BaseComponent } from '../../base.component';
import { FacetWrapperComponent } from '../facet-wrapper/facet-wrapper.component';
import defaultHtml from './facets-list.component.hbs';

/**
 * The Facets List component displays a list of available facets with a toggle to expand/collapse on mobile devices.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-facets-list>`.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-mobile-toggle | |
 *
 * When an element with this attribute is clicked, the {@link Models.FacetsListComponentModel.expanded} value will be reversed. This is intended to show/hide the list of facets on mobile devices.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./facets-list.component.hbs}
 *
 * @category Search
 */
export class FacetsListComponent extends BaseComponent<FacetsListComponentConfig, Array<Facet>, FacetsListComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'facets-list';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = true;

    private readonly state: {
        [key: number]: FacetState;
        expanded: boolean;
    } = {
        expanded: false
    };

    protected override renderContent(): boolean {
        return !!this.data;
    }

    protected override getContentModel(): FacetsListComponentModel {
        const supportedTypes: Array<FacetType> = [
            'checkbox',
            'color',
            'date-range',
            'link',
            'numeric-range',
            'range-slider',
            'recent-searches',
            'related-searches',
            'search',
            'size'
        ];

        return {
            facets: this.data!.filter((f) => supportedTypes.includes(f.type)),
            expanded: this.state.expanded,
            strings: {
                heading: this.configuration?.strings?.heading ?? 'Narrow Results'
            }
        };
    }

    protected override bindChildElements(): void {
        super.bindChildElements();

        this.rootElement.querySelectorAll<FacetWrapperComponent>('hawksearch-facet-wrapper[hawksearch-facet-id]').forEach((component) => {
            const id = parseInt(component.getAttribute('hawksearch-facet-id') ?? '');
            const facet = this.data?.find((f) => f.id === id);

            let facetValues: Array<string> | undefined;

            if (facet?.values) {
                facetValues = [];

                const addValues = (values: Array<FacetValue> | undefined): void => {
                    values?.forEach((v) => {
                        if (v.value) {
                            facetValues!.push(v.value);
                        }

                        addValues(v.children);
                    });
                };

                addValues(facet.values);
            }

            let facetState = this.state[id];

            if (!facetState) {
                facetState = {
                    id: id,
                    toggled: false,
                    collapsed: facet?.collapsed,
                    values: {}
                };

                this.state[id] = facetState;
            }

            facetValues?.forEach((v) => {
                const state = facetState.values![v];

                if (!state) {
                    facetState.values![v] = {
                        toggled: false
                    };
                }
            });

            component.data = facet;
            component.state = facetState;
        });
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-mobile-toggle]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();

                this.state.expanded = !this.state.expanded;

                this.render();
            });
        });
    }
}
