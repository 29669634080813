import { HawkSearchComponents, TabsComponentConfig } from '@configuration';
import { Facet, TabsComponentModel } from '@models';
import { searchService } from '@services';
import { BaseComponent } from '../../base.component';
import defaultHtml from './tabs.component.hbs';

/**
 * The Tabs component is used to segment search results by item type, often products and content.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-tabs>`.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-tab-value | `string` |
 *
 * When an element with this attribute is clicked, the specified tab facet value will be selected and the search results will update.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./tabs.component.hbs}
 *
 * @category Search
 */
export class TabsComponent extends BaseComponent<TabsComponentConfig, Facet, TabsComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'tabs';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = true;

    protected override renderContent(): boolean {
        return !!this.data?.values?.length;
    }

    protected override getContentModel(): TabsComponentModel {
        return {
            displayCount: this.data!.displayCount ?? true,
            tabs: this.data!.values ?? []
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-tab-value]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();

                const element = event.currentTarget as HTMLElement;
                const value = element.getAttribute('hawksearch-tab-value');

                searchService.selectTab(value ?? undefined);
            });
        });
    }
}
