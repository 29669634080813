import { HawkSearchComponents, TooltipComponentConfig } from '@configuration';
import { TooltipComponentModel } from '@models';
import { BaseComponent } from '../../base.component';
import defaultHtml from './tooltip.component.hbs';

/**
 * The Tooltip component is used to display a brief explanation or contextual information when the user hovers over an element.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-tooltip>`.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-tooltip | |
 * | hawksearch-tooltip-content | |
 *
 * These attributes are used to position the tooltip based on the size and scroll position of the active window. The `hawksearch-tooltip` attribute should be present on an element containing both the element the tooltip is attached to (to display on hover) and the element containing the tooltip content, which should have a `hawksearch-tooltip-content` attribute.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./tooltip.component.hbs}
 *
 * @category General
 */
export class TooltipComponent extends BaseComponent<TooltipComponentConfig, string, TooltipComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'tooltip';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = false;

    private get text(): string | undefined {
        return this.getAttribute('text') ?? undefined;
    }

    protected override renderContent(): boolean {
        return !!this.text;
    }

    protected override getContentModel(): TooltipComponentModel {
        return {
            text: this.text!
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelector('[hawksearch-tooltip]')?.addEventListener('mouseover', (event: Event) => {
            const element = event.currentTarget as HTMLElement;
            const contentElement = this.rootElement.querySelector('[hawksearch-tooltip-content]') as HTMLElement;

            if (!contentElement) {
                return;
            }

            contentElement.style.removeProperty('top');
            contentElement.style.removeProperty('right');

            const windowWidth = window.innerWidth;
            const boundingRect = element.getBoundingClientRect();
            const rightPosition = boundingRect.x + boundingRect.width;
            const maxWidth = 350;
            const margin = 20;

            contentElement.style.top = `${boundingRect.top}px`;

            if (windowWidth - rightPosition < maxWidth + margin) {
                contentElement.style.right = `${margin}px`;
            }
        });
    }
}
