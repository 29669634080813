import { get } from 'lodash-es';
import { HawkSearchGlobal } from '@configuration';
import { RawRecommendationsRequest, RawRecommendationsResponse, RecommendationsRequest, RecommendationsResponse } from '@models';
import { BaseService } from './base.service';

declare let HawkSearch: HawkSearchGlobal;

export class RecommendationsService extends BaseService {
    protected baseUrl = HawkSearch.config.endpoints?.recommendations || 'https://recs-dev.hawksearch.net/api/recommendation/v2';

    private fieldMappings = {
        imageUrl: HawkSearch.config.fieldMappings?.recommendations?.imageUrl || 'imageUrl',
        price: HawkSearch.config.fieldMappings?.recommendations?.price || 'price',
        salePrice: HawkSearch.config.fieldMappings?.recommendations?.salePrice || 'salePrice',
        title: HawkSearch.config.fieldMappings?.recommendations?.title || 'itemName',
        description: HawkSearch.config.fieldMappings?.recommendations?.description || 'shortDescription',
        url: HawkSearch.config.fieldMappings?.recommendations?.url || 'url'
    };

    // #region Search Operations

    getItems(recommendationsRequest: RecommendationsRequest): Promise<RecommendationsResponse> {
        return this.executeRecommendations(recommendationsRequest);
    }

    private executeRecommendations(recommendationsRequest: RecommendationsRequest): Promise<RecommendationsResponse> {
        const rawRecommendationsRequest: RawRecommendationsRequest = {
            clientGuid: HawkSearch.config.clientId,
            indexName: HawkSearch.config.index,
            landingPageUrl: recommendationsRequest.url,
            renderHTML: false,
            visitId: this.getVisitId(),
            visitorId: this.getVisitorId(),
            widgetUids: [
                {
                    widgetGuid: recommendationsRequest.widgetId,
                    uniqueid: recommendationsRequest.itemId
                }
            ]
        };

        this.triggerEvent('hawksearch:before-recommendations-executed', rawRecommendationsRequest);

        return this.httpPost<RawRecommendationsResponse>('/getwidgetitems', rawRecommendationsRequest).then((rawRecommendationsResponse) => {
            this.triggerEvent('hawksearch:after-recommendations-executed', rawRecommendationsResponse);

            const recommendationsResponse = this.convertResponse(rawRecommendationsResponse);

            this.triggerEvent('hawksearch:recommendations-completed', recommendationsResponse);

            this.bindComponents(recommendationsResponse);

            return recommendationsResponse;
        });
    }

    private convertResponse(rawRecommendationsResponse: RawRecommendationsResponse): RecommendationsResponse {
        (window as any)['test'] = rawRecommendationsResponse?.widgetItems?.[0]?.recommendationItems;

        return {
            requestId: rawRecommendationsResponse.requestId,
            widgets: rawRecommendationsResponse?.widgetItems?.map((w) => ({
                id: w.widgetGuid,
                requestId: rawRecommendationsResponse.requestId,
                title: w.widgetName,
                carousel: w.isCarousel,
                items: w.recommendationItems?.map((i: any) => ({
                    id: i.id,
                    imageUrl: this.getUrl(HawkSearch.config.urlPrefixes?.content, get(i, this.fieldMappings.imageUrl)),
                    title: get(i, this.fieldMappings.title),
                    description: get(i, this.fieldMappings.description),
                    url: this.getUrl(HawkSearch.config.urlPrefixes?.content, get(i, this.fieldMappings.url))!,
                    price: parseFloat(get(i, this.fieldMappings.price)) || undefined,
                    salePrice: parseFloat(get(i, this.fieldMappings.salePrice)) || undefined,
                    attributes: i
                }))
            }))
        };
    }

    // #endregion Search Operations

    // #region Events

    bindComponents(recommendationsResponse: RecommendationsResponse): void {
        recommendationsResponse.widgets?.forEach((w) => {
            this.triggerBindEvent('recommendations', w, w.id);
        });
    }

    // #endregion Events
}

export const recommendationsService = new RecommendationsService();
