import { formatCurrency, formatNumber } from '@utilities';
import { HawkSearchGlobal } from '@configuration';

declare let HawkSearch: HawkSearchGlobal;

/**
 * This function returns the sum of two values.
 *
 * ### Example
 *
 * ```hbs
 * {{sum 1 2}}
 * ```
 *
 * @param value1 First value
 * @param value2 Second value
 * @returns Sum
 */
export function add(value1: number, value2: number): number {
    return value1 + value2;
}

/**
 * This function returns whether all values resolve to `true`.
 *
 * ### Example
 *
 * ```hbs
 * {{#if (and value1 value1)}}
 *   <span>Conditional content</span>
 * {{/if}}
 * ```
 *
 * @param values Array of value
 * @returns `true` if all values resolve to `true`, otherwise `false`
 */
export function and(...values: Array<any>): boolean {
    return values.slice(0, -1).every((v) => !!v); // Omit last argument as that is the Handlebars options function
}

/**
 * This function renders an inline attribute when `condition` resolves to `true`.
 *
 * ### Example
 *
 * ```hbs
 * <div{{attribute ' style="display: block;"' visible}}></div>
 * ```
 *
 * @param attribute The attribute to be rendered
 * @param condition The value that is evaluated to determine whether to render `attribute`
 * @returns `attribute` if `condition` resolves to `true`, otherwise an empty string
 */
export function attribute(attribute: string, condition: any): Handlebars.SafeString {
    return new HawkSearch.handlebars.SafeString(condition ? attribute : '');
}

/**
 * This function concatenates multiple values into a single string
 *
 * ### Example
 *
 * ```hbs
 * <div{{attribute (concat 'style="max-height: ' maxHeight 'px;"') maxHeight}}></div>
 * ```
 *
 * @param values Array of values
 * @returns Concatenated string
 */
export function concat(...values: Array<any>): string {
    if (values.length < 2) {
        return '';
    }

    return values.slice(0, -1).join(''); // Omit last argument as that is the Handlebars options function
}

/**
 * This function returns whether the supplied values are equal to each other.
 *
 * @param value1 First value
 * @param value2 Second value
 * @returns Whether `value1` and `value2` are equal
 */
export function eq(value1: any, value2: any): boolean {
    return value1 === value2;
}

/**
 * This function is used to prevent Handlebars from attempting to bind a data model to a nested control containing a custom template.
 *
 * ### Example
 *
 * ```hbs
 * <hawksearch-results>
 *   {{{{exclude}}}}
 * 	   <hawksearch-pagination>
 * 	     {{#if displayFirstLink}}
 *         <a hawksearch-page="1">First</a>
 * 	     {{/if}}
 *       {{#if displayLastLink}}
 *         <a hawksearch-page="{{totalPages}}">Last</a>
 *       {{/if}}
 *     </hawksearch-pagination>
 *   {{{{/exclude}}}}
 * </hawksearch-results>
 * ```
 *
 * @param options Automatically added by Handlebars
 * @returns The preserved Handlebars template without binding it to the parent component data object
 */
export function exclude(options: any): any {
    return options.fn();
}

/**
 * This function is used to render a provided string as HTML rather than escape special characters.
 *
 * @param html HTML string
 * @returns Unencoded HTML string
 */
export function html(html: string): Handlebars.SafeString {
    return new HawkSearch.handlebars.SafeString(html);
}

/**
 * This function formats a value as currency
 *
 * @param value Number to be formatted
 * @param decimals Number of decimal places to display
 * @param defaultValue Default value
 * @returns Formatted currency string
 */
export function currency(value: number | Array<number> | undefined, decimals: number | undefined, defaultValue?: number): string | undefined {
    if (typeof decimals === 'object') {
        decimals = undefined;
    }

    if (typeof defaultValue === 'object') {
        defaultValue = undefined;
    }

    if (value instanceof Array) {
        value = value[0];
    }

    return formatCurrency(value ?? defaultValue ?? 0, decimals);
}

/**
 * This function returns whether the first provided value is greater than the second provided value.
 *
 * @param value1 First value
 * @param value2 Second value
 * @returns Whether `value1` is greater than `value2`
 */
export function gt(value1: any, value2: any): boolean {
    return value1 > value2;
}

/**
 * This function returns whether the first provided value is greater than or equal to the second provided value.
 *
 * @param value1 First value
 * @param value2 Second value
 * @returns Whether `value1` is greater than or equal to `value2`
 */
export function gte(value1: any, value2: any): boolean {
    return value1 >= value2;
}

/**
 * This function is used to render different content inline depending on whether a condition resolves to `true` or `false`.
 *
 * ### Example
 * ```hbs
 * <hawksearch-icon name="{{if-else toggled 'chevron-down' 'chevron-right'}}"></hawksearch-icon>
 * ```
 *
 * @param condition Condition to be evaluated
 * @param trueValue The value to be returned if `condition` resolves to `true`
 * @param falseValue The value to be returned if `condition` resolves to `false`
 * @returns Either `trueValue` or `falseValue` depending on `condition`
 */
export function ifElse(condition: any, trueValue: any, falseValue: any): any {
    return condition ? trueValue : falseValue;
}

/**
 * This function returns whether the first provided value is less than the second provided value.
 *
 * @param value1 First value
 * @param value2 Second value
 * @returns Whether `value1` is less than `value2`
 */
export function lt(value1: any, value2: any): boolean {
    return value1 < value2;
}

/**
 * This function returns whether the first provided value is less than or equal to the second provided value.
 *
 * @param value1 First value
 * @param value2 Second value
 * @returns Whether `value1` is less than or equal to `value2`
 */
export function lte(value1: any, value2: any): boolean {
    return value1 <= value2;
}

/**
 * This function formats a number.
 *
 * @param value Number to be formatted
 * @param decimals Number of decimal places to display
 * @param defaultValue Default value
 * @returns Formatted number string
 */
export function number(value: number | Array<number> | undefined, decimals: number, defaultValue?: number): string | undefined {
    if (typeof defaultValue === 'object') {
        defaultValue = undefined;
    }

    if (value instanceof Array) {
        value = value[0];
    }

    return formatNumber(value ?? defaultValue ?? 0, decimals);
}

/**
 * This function returns whether any provided value resolves to `true`.
 *
 * @param values Array of values
 * @returns Whether any value resolves to `true`
 */
export function or(...values: Array<any>): boolean {
    return values.slice(0, -1).some((v) => !!v); // Omit last argument as that is the Handlebars options function
}

/**
 * This function displays a specified string with an optional default value.
 *
 * @param value Value to be returned
 * @param defaultValue Default value if `value` does not resolve to `true`
 * @returns `value` if it resolves to `true`, otherwise `defaultValue`
 */
export function string(value: string | Array<string> | undefined, defaultValue?: string): string | undefined {
    if (typeof defaultValue === 'object') {
        defaultValue = undefined;
    }

    if (value instanceof Array) {
        value = value[0];
    }

    return value ?? defaultValue;
}

/**
 * This function returns the difference of two values
 *
 * @param value1 First value
 * @param value2 Second value
 * @returns Difference
 */
export function subtract(value1: number, value2: number): number {
    return value1 - value2;
}
