import placeholderUrl from '@assets/images/placeholder.png';
import { HawkSearchComponents, HawkSearchGlobal, SearchResultsItemComponentConfig } from '@configuration';
import { SearchResultsItem, SearchResultsItemComponentModel } from '@models';
import { trackingService } from '@services';
import { BaseComponent } from '../../base.component';
import defaultHtml from './search-results-item.component.hbs';

declare let HawkSearch: HawkSearchGlobal;

/**
 * The Search Results Item component displays information for an individual product or page.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-search-results-item>`.
 *
 * *Note: This component should only be used within the context of the {@link Components.SearchResultsListComponent | Search Results List component}.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-image | |
 *
 * Image elements with this attribute will have their `src` value replaced with a placeholder image URL if the image fails to load.
 *
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-link | |
 *
 * Anchor elements with this attribute will be tracked when clicked.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./search-results-item.component.hbs}
 *
 * @category Search
 */
export class SearchResultsItemComponent extends BaseComponent<SearchResultsItemComponentConfig, SearchResultsItem, SearchResultsItemComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'search-results-item';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = false;

    protected override renderContent(): boolean {
        return !!this.data;
    }

    protected override getContentModel(): SearchResultsItemComponentModel {
        return {
            ...this.data!,
            strings: {
                sale: this.configuration?.strings?.sale ?? 'Sale'
            }
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-image]').forEach((e) => {
            e.addEventListener('error', (event: Event): void => {
                const element = event.currentTarget as HTMLImageElement;

                element.src = HawkSearch.config.placeholderImageUrl || placeholderUrl;
            });
        });

        this.rootElement.querySelectorAll('[hawksearch-link]').forEach((e) => {
            e.addEventListener('click', ((event: PointerEvent): void => {
                if (!this.data) {
                    return;
                }

                trackingService.trackSearchResultClick(this.data.id, this.data.url, event);
            }) as EventListener);
        });
    }
}
