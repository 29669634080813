import { HawkSearchGlobal } from '@configuration';

declare let HawkSearch: HawkSearchGlobal;

export function formatCurrency(value: number, decimals = 2): string {
    const cultureIsoCode = HawkSearch.config.formatting?.cultureIsoCode || 'en-US';
    const currencyIsoCode = HawkSearch.config.formatting?.currencyIsoCode?.toUpperCase() || 'USD';

    return value.toLocaleString(cultureIsoCode, {
        style: 'currency',
        currency: currencyIsoCode,
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    });
}

export function formatDateIso(value: Date): string {
    return `${value.getFullYear()}-${(value.getMonth() + 1).toString().padStart(2, '0')}-${value.getDate().toString().padStart(2, '0')}`;
}

export function formatDateShort(value: Date): string {
    const cultureIsoCode = HawkSearch.config.formatting?.cultureIsoCode || 'en-US';

    return value.toLocaleString(cultureIsoCode, {
        year: 'numeric',
        month: 'short',
        day: '2-digit'
    });
}

export function formatNumber(value: number, decimals = 2): string {
    const cultureIsoCode = HawkSearch.config.formatting?.cultureIsoCode || 'en-US';

    return value.toLocaleString(cultureIsoCode, {
        style: 'decimal',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    });
}
