/**
 * @category Tracking
 */
export enum AutocompleteItemType {
    Search = 1,
    Category = 2,
    Product = 3,
    Content = 4
}

/**
 * @category Tracking
 */
export enum EventType {
    PageLoad = 1,
    Search = 2,
    Click = 3,
    AddToCart = 4,
    Rate = 5,
    Sale = 6,
    BannerClick = 7,
    BannerImpression = 8,
    RecommendationClick = 10,
    AutocompleteClick = 11,
    AddToCartMultiple = 14
}

/**
 * @category Tracking
 */
export enum PageType {
    Item = 1,
    Landing = 2,
    Cart = 3,
    Order = 4,
    Custom = 5
}

/**
 * @category Tracking
 */
export enum SearchType {
    Initial = 1,
    Refinement = 2
}
