import { HawkSearchComponents, LandingPageComponentConfig } from '@configuration';
import { LandingPageComponentModel, PageType, SearchResponse } from '@models';
import { searchService, trackingService } from '@services';
import { BaseComponent } from '../../base.component';
import defaultHtml from './landing-page.component.hbs';

/**
 * The Landing page component displays either a search results interface to display a subset of products, or a custom layout to display content items.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-landing-page>`.
 *
 * ## Attributes
 * | Name | Value | Required |
 * | :- | :- | :- |
 * | url | `string` | No |
 *
 * By default, the URL sent to the HawkSearch API matches the current browser URL as reflected in `window.location.pathname`. This attribute can be used to override this with a custom value.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./landing-page.component.hbs}
 *
 * @category Landing Pages
 */
export class LandingPageComponent extends BaseComponent<LandingPageComponentConfig, SearchResponse, LandingPageComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'landing-page';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = true;

    protected get url(): string {
        return this.getAttribute('url') ?? window.location.pathname;
    }

    constructor() {
        super();

        trackingService.trackPageLoad(PageType.Landing);

        const searchRequest = searchService.getRequestFromUrl();

        searchRequest.url = this.url;

        searchService.search(searchRequest);
    }

    protected override renderContent(): boolean {
        return !!this.data;
    }

    protected override getContentModel(): LandingPageComponentModel {
        return {
            ...this.data!,
            customLayout: this.processCustomLayout(this.data!.customLayout),
            url: this.url
        };
    }

    private processCustomLayout(layout: string | undefined): string | undefined {
        if (!layout) {
            return undefined;
        }

        let output = layout;

        const zones = Object.keys(this.data!.contentZones ?? {});
        const zonesRegex = new RegExp(`{{(${zones.join('|')})}}`, 'g');

        output = output.replace(zonesRegex, `<hawksearch-content-zone name="$1"></hawksearch-content-zone>`);
        output = output.replace(/{{#breadcrumb#}}/g, this.data!.content?.breadcrumbs ?? '');
        output = output.replace(/{{#pageheading#}}/g, this.data!.content?.heading ?? '');
        output = output.replace(/{{#customhtml#}}/g, this.data!.content?.customHtml ?? '');

        return output;
    }
}
